import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'

import { MapPin2 } from '@styled-icons/remix-fill'

let mapkey = 'AIzaSyDomXoCWj_kLjKROHOBRZIZ25dgPiI0gec'

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: -6.29,
      lng: 106.66
    },
    zoom: 1
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapkey }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marker lat={-6.299412} lng={106.667682} text={'My Location'} />
        </GoogleMapReact>
      </div>
    )
  }
}

export default GoogleMap

const Marker = () => {
  return (
    <div style={{ color: 'red' }}>
      <MapPin2 size="2em" style={{ transform: 'translate(-50%, -100%)' }} />
    </div>
  )
}
